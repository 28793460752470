import clsx from 'clsx';
import { useContext } from 'react';

import { PrimitivePopoverContext } from '../popover.context';

import style from './style.module.scss';

interface IPrimitivePopoverPanelProps extends ICustomizable {
  children: React.ReactNode;
}

export const PrimitivePopoverPanel = (props: IPrimitivePopoverPanelProps) => {
  const { trigger, popover } = useContext(PrimitivePopoverContext);

  return (
    <div
      {...trigger.overlayProps}
      className={clsx(style['popover-panel'], props.className)}
      data-placement={popover.placement}
    >
      {props.children}
    </div>
  );
};
