import React, { forwardRef } from 'react';
import { clsx } from 'clsx';

import { ComboFieldContext } from '../ComboField/combo-field.context';
import style from './style.module.scss';

export interface IFieldBoxProps extends ICustomizable {
  /** A config to handle border-radius and spacing when it used inside ComboField component */
  magnet?: IMagnet;
  children: React.ReactNode;
}

export const FieldBox = forwardRef(
  ({ className, magnet, children, ...groupProps }: IFieldBoxProps, ref: React.MutableRefObject<HTMLDivElement>) => {
    const context = React.useContext(ComboFieldContext);

    return (
      <div
        {...groupProps}
        className={clsx(style['field-box'], className)}
        data-magnet={magnet}
        aria-invalid={context.invalid || groupProps['aria-invalid']}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);
