import clsx from 'clsx';
import { useContext } from 'react';
import { DismissButton, Overlay } from 'react-aria';

import { PrimitivePopoverContext } from '../popover.context';

import style from './style.module.scss';

interface IPrimitivePopoverPortalProps extends ICustomizable {
  underlayClass?: string;
  children: React.ReactNode;
}

export const PrimitivePopoverPortal = (props: IPrimitivePopoverPortalProps) => {
  const { state, popover, popoverRef } = useContext(PrimitivePopoverContext);

  if (!state.isOpen) {
    return <></>;
  }

  return (
    <Overlay>
      <div {...popover.underlayProps} className={style['popover-portal-underlay']} />
      <div
        {...popover.popoverProps}
        ref={popoverRef}
        className={clsx(style['popover-portal'], props.className)}
        data-placement={popover.placement}
      >
        <DismissButton onDismiss={state.close} />
        {props.children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
};
