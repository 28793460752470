import { clsx } from 'clsx';
import { isNil } from 'rambdax';
import { useReducer, useRef } from 'react';
import { HiddenSelect, useSelect } from 'react-aria';
import { Item, useSelectState } from 'react-stately';

import CaretDownIcon from '@ping/assets/Icon/caret-down.svg';
import { useToggle } from '@ping/hooks';
import { FieldGroup, ListBox, Loading, PrimitivePopover } from '@ping/uikit';

import style from './style.module.scss';

import type IReactStately from 'react-stately';
import { type PopoverPlacement } from '../PrimitivePopover/PrimitivePopoverRoot';

const BORDER_WIDTH = 1 as const;

export interface ISelectProps<T> extends IReactStately.SelectProps<T>, ICustomizable {
  emptyState?: React.ReactNode;
  name?: string | undefined;
  topElement?: React.ReactNode;
  magnet?: IMagnet;
  boundaryRef?: React.MutableRefObject<HTMLElement>;
  buttonContent?: React.ReactNode;
  isLoading?: boolean;
  wrapperClassName?: string;
  onPanelPlacementChange?: (placement: PopoverPlacement) => void;
}

// TODO: break this component into multiple sub-components, styling and handling more logic is not clean.
export const Select = <T extends object>(props: ISelectProps<T>) => {
  //
  // Create state based on the incoming props
  const state = useSelectState(props);
  const [isPopoverTriggerFocused, togglePopoverTriggerFocusState] = useToggle(false);
  //
  // Get props for child elements from useSelect
  const boundaryRef = useRef<HTMLDivElement>();
  const triggerRef = useRef();
  const { labelProps, triggerProps, valueProps, menuProps } = useSelect(props, state, triggerRef);
  //
  // Set state based on child popover element
  const [placement, emitPlacementChange] = useReducer<
    (prev: PopoverPlacement, next: PopoverPlacement) => PopoverPlacement
  >((prev, next) => {
    if (prev !== next) {
      props.onPanelPlacementChange?.(next);
    }
    return next;
  }, 'bottom');

  return (
    <FieldGroup className={clsx(style['select'], props.wrapperClassName)}>
      {!isNil(props.label) && (
        <FieldGroup.Label {...labelProps} className={style['select__label']}>
          {props.label}
        </FieldGroup.Label>
      )}

      <FieldGroup.Control
        className={style['select__control']}
        magnet={props.magnet}
        ref={isNil(props.boundaryRef) ? boundaryRef : undefined}
        data-part='select-control'
        data-state={state.isOpen ? 'open' : 'closed'}
        data-placement={placement}
      >
        <HiddenSelect state={state} triggerRef={triggerRef} label={props.label} name={props.name} />

        <PrimitivePopover.Root
          state={state}
          triggerRef={triggerRef}
          boundaryRef={props.boundaryRef ?? boundaryRef}
          offset={BORDER_WIDTH}
          onFlipped={emitPlacementChange}
        >
          <PrimitivePopover.Trigger
            {...triggerProps}
            className={style['select__trigger']}
            data-magnet={props.magnet}
            onFocusChange={togglePopoverTriggerFocusState}
          >
            <span {...valueProps}>
              {props.buttonContent
                ? props.buttonContent
                : state.selectedItem
                ? state.selectedItem.rendered
                : props.placeholder}
            </span>

            <Loading className={style['select__loading']} data-hidden={!props.isLoading} aria-hidden='true' />
            <CaretDownIcon
              className={style['select__indicator']}
              data-hidden={props.isLoading}
              data-open={state.isOpen}
              aria-hidden='true'
            />
          </PrimitivePopover.Trigger>

          {!props.isLoading && !state.collection.size && !isPopoverTriggerFocused && (
            <p className={style['select__empty-state']}>{props.emptyState}</p>
          )}

          <PrimitivePopover.Portal>
            <PrimitivePopover.Panel
              className={clsx(style['select__panel'], props.className)}
              data-part='popover-content'
            >
              {props.topElement}
              <ListBox
                children={undefined}
                {...menuProps}
                className={style['select__list']}
                data-placement={placement}
                state={state}
              />
            </PrimitivePopover.Panel>
          </PrimitivePopover.Portal>
        </PrimitivePopover.Root>
      </FieldGroup.Control>
    </FieldGroup>
  );
};

interface ISelectItemProps {
  <T>(props: IReactStately.ItemProps<T> & ICustomizable): JSX.Element;
}

Select.Item = Item as ISelectItemProps;
