import { type } from 'rambdax';
import { useReducer } from 'react';

/**
 * Returns a tuple containing a boolean value and a function to toggle the boolean value.
 * @param {boolean} initialState - The `initialState` parameter is the initial value for the boolean
 * state that will be toggled. It determines the starting state of the toggle functionality.
 * @returns The `useToggle` function returns an array with two elements:
 * 1. The first element is a boolean value representing the current state of the toggle.
 * 2. The second element is a function that can be used to toggle the state. It accepts an optional
 * boolean parameter `force` which, despite being `any` must be a `boolean` type. if a boolean `force` provided, will set the state to that value. If `force` is not
 * provided or not a `boolean` type, it will toggle.
 */
export const useToggle = (initialState: boolean): [boolean, (force?: any) => void] => {
  return useReducer((state: boolean, force?: any) => (type(force) === 'Boolean' ? force : !state), initialState);
};
