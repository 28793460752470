import { useContext } from 'react';

import { ButtonA11Y } from '@ping/uikit';

import { PrimitivePopoverContext } from '../popover.context';

interface IPrimitivePopoverTriggerProps extends React.ComponentProps<typeof ButtonA11Y> {
  children: React.ReactNode;
}

export const PrimitivePopoverTrigger = (props: IPrimitivePopoverTriggerProps) => {
  const { className, children, ...buttonProps } = props;
  const { trigger, triggerRef, popover } = useContext(PrimitivePopoverContext);

  return (
    <ButtonA11Y
      {...trigger.triggerProps} // NOTE: don't change the order props
      {...buttonProps} // NOTE: don't change the order props
      className={className}
      data-placement={popover.placement}
      isPrimitive
      buttonRef={triggerRef}
    >
      {children}
    </ButtonA11Y>
  );
};
