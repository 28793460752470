import { clsx } from 'clsx';

import { FieldBox } from '../FieldBox';
import style from './style.module.scss';

interface IFieldGroupProps extends ICustomizable {
  children: React.ReactNode;
}

export const FieldGroup = (props: IFieldGroupProps) => (
  <div className={clsx(style['field-group'], props.className)} data-part={props['data-part']}>
    {props.children}
  </div>
);

interface IFieldGroupLabelProps extends ICustomizable, React.LabelHTMLAttributes<HTMLLabelElement> {
  children: React.ReactNode;
}

const FieldGroupLabel = ({ children, className, ...labelProps }: IFieldGroupLabelProps) => (
  <label className={clsx(style['field-group-label'], className)} {...labelProps}>
    {children}
  </label>
);

FieldGroup.Label = FieldGroupLabel;
FieldGroup.Control = FieldBox;

export default FieldGroup;
