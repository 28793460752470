import clsx from 'clsx';
import { useRef } from 'react';
import { mergeProps, useFocusRing, useListBox, useOption, VisuallyHidden } from 'react-aria';
import { Item, useListState } from 'react-stately';

import style from './style.module.scss';

import type IRactAria from 'react-aria';
import type IReactStately from 'react-stately';

interface IListBoxProps<T> extends IReactStately.ListProps<T>, IRactAria.AriaListBoxOptions<T>, ICustomizable {
  state?: IReactStately.ListState<T>;
}

interface IListBoxOptionProps<T> {
  item: IReactStately.SingleSelectListState<T>['selectedItem'];
  state: IReactStately.ListState<T>;
}

interface IListBoxItemProps {
  <T>(props: IReactStately.ItemProps<T> & ICustomizable): JSX.Element;
}

export const ListBox = <T extends object>(props: IListBoxProps<T>) => {
  // Create state based on the incoming props
  const state = props.state ?? useListState(props);

  // Get props for the listbox element
  const listBoxRef = useRef<HTMLUListElement>();
  const { listBoxProps, labelProps } = useListBox(props, state, listBoxRef);

  return (
    <>
      <VisuallyHidden>
        <label {...labelProps}>{props.label}</label>
      </VisuallyHidden>

      <ul className={clsx(style['listbox'], props.className)} {...listBoxProps} ref={listBoxRef} data-part='listbox'>
        {[...state.collection].map(item => {
          const lastGroup = null;

          if ((item.value as any)?.group && item.value['group'] !== lastGroup) {
            lastGroup === item.value['group'];
            return (
              <>
                <span className={style['listbox__group']}>{item.value['group']}</span>
                <Option key={item.key} item={item} state={state} />
              </>
            );
          }
          return <Option key={item.key} item={item} state={state} />;
        })}
      </ul>
    </>
  );
};

const Option = <T,>({ item, state }: IListBoxOptionProps<T>) => {
  // Get props for the option element
  const ref = useRef();
  const { optionProps } = useOption({ key: item.key }, state, ref);

  // Determine whether we should show a keyboard
  // focus ring for accessibility
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <li
      className={clsx(style['listbox-option'], item.props.className)}
      data-is-last-item={item.key === state.collection.getLastKey()}
      data-is-focus-visible={isFocusVisible}
      {...mergeProps(optionProps, focusProps)}
      ref={ref}
    >
      {item.rendered}
    </li>
  );
};

ListBox.Item = Item as IListBoxItemProps;
