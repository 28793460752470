import { PrimitivePopoverPanel } from './PrimitivePopoverPanel';
import { PrimitivePopoverPortal } from './PrimitivePopoverPortal';
import { PrimitivePopoverRoot } from './PrimitivePopoverRoot';
import { PrimitivePopoverTrigger } from './PrimitivePopoverTrigger';

export const PrimitivePopover = Object.freeze({
  Panel: PrimitivePopoverPanel,
  Portal: PrimitivePopoverPortal,
  Root: PrimitivePopoverRoot,
  Trigger: PrimitivePopoverTrigger,
});
