import { createContext } from 'react';

import type IReactAria from 'react-aria';
import type IReactStately from 'react-stately';

interface IPrimitivePrimitivePopoverContext {
  state: IReactStately.OverlayTriggerState;
  trigger: IReactAria.OverlayTriggerAria;
  triggerRef: React.MutableRefObject<HTMLButtonElement>;
  popover: IReactAria.PopoverAria;
  popoverRef: React.MutableRefObject<HTMLDivElement>;
}

export const PrimitivePopoverContext = createContext<IPrimitivePrimitivePopoverContext>({
  state: null,
  trigger: null,
  triggerRef: null,
  popover: null,
  popoverRef: null,
});
