import { isNil } from 'rambdax';
import { useRef } from 'react';
import { useOverlayTrigger, usePopover } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { useClientEffect } from '@ping/hooks';

import { PrimitivePopoverContext } from '../popover.context';

import type IReactAria from 'react-aria';
import type IReactStately from 'react-stately';

type OverlayTriggerStateProps = Parameters<typeof useOverlayTriggerState>[0];
type PopoverProps = Omit<Parameters<typeof usePopover>[0], 'triggerRef' | 'popoverRef'>;
export type PopoverPlacement = IReactAria.PopoverAria['placement'];

interface IPrimitivePopoverRootProps extends OverlayTriggerStateProps, PopoverProps {
  children: React.ReactNode;
  state?: IReactStately.OverlayTriggerState;
  triggerRef?: React.MutableRefObject<HTMLButtonElement>;
  boundaryRef?: React.MutableRefObject<HTMLElement>;
  onFlipped?: (placement: PopoverPlacement) => void;
}

export const PrimitivePopoverRoot = (props: IPrimitivePopoverRootProps) => {
  //
  // setup trigger
  const triggerRef = props.triggerRef ?? useRef<HTMLButtonElement>(null);
  const state = props.state ?? useOverlayTriggerState(props);
  const trigger = useOverlayTrigger({ type: 'dialog' }, state, triggerRef);
  //
  // setup panel
  const popoverRef = useRef<HTMLDivElement>(null);
  const popover = usePopover({ ...props, triggerRef: props.boundaryRef ?? triggerRef, popoverRef }, state);
  //
  // Adjust panel inline size to the boundary element
  if (!isNil(props.boundaryRef?.current)) {
    const bound = props.boundaryRef.current.getBoundingClientRect();
    popover.popoverProps.style.minInlineSize = `${bound.width}px`;
    popover.popoverProps.style.maxInlineSize = `${bound.width}px`;
  }

  useClientEffect(() => props.onFlipped?.(popover.placement), [popover.placement]);

  return (
    <PrimitivePopoverContext.Provider value={{ state, trigger, triggerRef, popover, popoverRef }}>
      {props.children}
    </PrimitivePopoverContext.Provider>
  );
};
